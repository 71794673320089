import React from 'react'
import './index.css'
import Section3Image1 from '../../../images/Section3Image1.svg'
import Section3Image2 from '../../../images/Section3Image2.svg'
import Section3Image3 from '../../../images/Section3Image3.svg'
import Section3Image4 from '../../../images/Section3Image4.svg'
import Section3Image5 from '../../../images/Section3Image5.svg'
const Section3 = () => {
  
  const Card = ({image, description}) => {
    return (
      <div className='card-innercontainer'>
        <img className='card-image' src={image} alt="" />
        <p className='card-description'>{description}</p>
      </div>
    )
  }
  
  return (
    <div className='section3'>
      <a className="anchor" id="BENEFICIOS" href=" "> </a> 
      <div className='title-container'>
        <p className="title-session-3">BENEFICÍOS</p>
        <div className="yellow-paralelogram" />
      </div>
      <div className='card-container'>
        <Card image={Section3Image1} description="Facilita a mistura de bebidas/suplementos"/>
        <Card image={Section3Image2} description="Tritura frutas sem necessidade de lâminas"/>
        <Card image={Section3Image3} description="Proporciona uma limpeza descomplicada, pois não acumula suplementos no fundo do copo"/>
        <Card image={Section3Image4} description="Melhora o aproveitamento do mix, evitando desperdício"/>
        <Card image={Section3Image5} description="Não necessita de molas/bolinhas de plástico"/>
      </div>
    </div>
  )
}

export default Section3