import React from 'react'
import './index.css'
import Elementos from '../../../images/Elementos.png';
import LINKS from '../../../utils/links';

const Section4 = () => {
    
  return (
    <div className='section4'>
      <a className="anchor" id="COMPRAR" href=" "> </a>
      <div className='title-section4-container'>
        <div className="yellow-line" />
        <p className="title-session-4">GARANTA AGORA MESMO A SUA COQUETELEIRA ORB!</p>
      </div>
      <div className='blue-line-container'>
        <div className='blue-line'/>
      </div>
      <div className='buttons-container'>
        <div className='buttons-inner-container'>
          <p className='button-title'>PEÇA PELO <b style={{fontWeight: 800}}>MERCADO LIVRE</b></p>
          <a href={LINKS.MERCADOLIVRE} target="_blank" rel="noreferrer">
            <button className='button-buy-section4'>COMPRAR<b style={{ marginLeft: '8%' }}>></b></button>
          </a>
        </div>
        <div className='buttons-inner-container'>
          <p className='button-title'>PEÇA PELA <b style={{ fontWeight: 800 }}>AMERICANAS</b></p>
          <a href={LINKS.AMERICANAS} target="_blank" rel="noreferrer">
            <button className='button-buy-section4'>COMPRAR<b style={{ marginLeft: '8%' }}>></b></button>
            </a>
        </div>
      </div>
      <img src={Elementos} alt="" style={{
        position: 'absolute',
        width: '10%',
        bottom: '29%',
        left: '4%'
      }} />
    </div>
  )
}

export default Section4