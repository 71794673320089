import React from 'react'
import BannerHomeOrb from '../../../images/BannerHomeOrb.png'

const Section1 = ()  => {
  return (
     <div className='section1'>
      <img src={BannerHomeOrb} alt="" width="100%" />
    </div>
  )
}

export default Section1