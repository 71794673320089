import React from 'react'
import LogoOrb from '../../../images/OrbLogo.svg'
import logoOrb from '../../../images/logo-orb.svg'
import logoPowerKing from '../../../images/logo-powerking.svg'
import './styles.css';
import LINKS from '../../../utils/links'

export default function SiteMobile() {

  return (
    <div className='mobile-container' style={{height: window.innerHeight}}>
      <div className='mobile-background'/>
      <div className='mobile-header'>
        <img src={LogoOrb} alt="" width={200} />
      </div>
      <div>
        <a className='button-buy-mobile' href={LINKS.MERCADOLIVRE}> 
          <p>MERCADO LIVRE</p>
          <p>></p>
        </a>
        <a className='button-buy-mobile' href={LINKS.AMERICANAS}> 
          <p>AMERICANAS</p>
          <p>></p>
        </a>
        <a className='button-buy-mobile' href={LINKS.INSTAGRAM}> 
          <p>INSTAGRAM</p>
          <p>></p>
        </a>
        <a className='button-buy-mobile' href={LINKS.WHATSAPP}> 
          <p>WHATSAPP</p>
          <p>></p>
        </a>
      </div>
      <div className='footer-mobile'>
        <img src={logoOrb} alt="" style={{marginRight: 35, width: 50}} />
        <img src={logoPowerKing} alt="" style={{width: 50}}/>
      </div>
    </div>
  )
}
