import React from 'react'
import './index.css'

import logoOrb from '../../../images/logo-orb.svg'
import logoPowerKing from '../../../images/logo-powerking.svg'

const Footer = () => {
  return (
    <div className='footer'>
      <img src={logoOrb} alt="" width="4%" style={{marginRight: '3%'}} />
      <img src={logoPowerKing} alt="" width="4%"/>
    </div>
  )
}

export default Footer