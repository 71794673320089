import React from 'react'

import Section2Image1 from '../../../images/Section2Image1.png';
import Section2Image2 from '../../../images/Section2Image2.png';
import ArrowUp from '../../../images/ArrowUp.svg';
import ArrowDown from '../../../images/ArrowDown.svg';
import Elementos from '../../../images/Elementos.png';
import './index.css'
const Section2 = ()  => {
  return (
    <div className='section2'>
      <a className="anchor" id="SOBRE" href=" "> </a>
      <div className="collumn">
        <div className="tag-poweking-product">
          <p className='tag-poweking-product-text'>{`UM PRODUTO `}</p>
          <p className='tag-poweking-product-text pk'>{`POWERKING`}</p>
        </div>
        <p className="title">O SEU JEITO É O NOSSO JEITO!</p>
        <p className="description">A coqueteleira ORB chegou para inovar o mercado fitness com seu design esférico, que proporciona 100% de aproveitamento dos suplementos e acaba com as dificuldades para a limpeza.</p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '63%'}}>
          <img src={ArrowDown} style={{ width: '50%', position: 'absolute', justifySelf: 'center', marginTop: '-3%', marginLeft: '35%' }} alt=""/>
          <img src={ArrowUp} style={{ width: '50%', position: 'absolute', justifySelf: 'center', marginTop: '45%' , marginLeft: '40%'}} alt=""/>
          <img src={Section2Image1} style={{ width: '140%', marginLeft: '35%', marginTop: '20%' }} alt=""/>
        </div>
      </div>
      <div className="collumn">
        <div className="tag-poweking-product decoration" />
        <img src={Section2Image2} alt="" style={{width: '120%', marginTop: '10%'}} />
        <p className="description second">Seu formato exclusivo conta com o fundo esférico, em formato de pílula — sem cantos, possibilitando triturar frutas apenas com o movimento de chacoalhar o copo na mão, sem o auxílio de lâminas.*</p>
        <p className="subDescription second">*Frutas macias: como banana, framboesa ou morango.</p>
        <img src={Elementos} alt="" style={{position: 'absolute', width: '35%', bottom: '15%', right: '-68%'}} />
      </div>
    </div>
  )
}

export default Section2