import React, { Component } from 'react'
import orbLogo from '../../images/OrbLogo.svg'
import Instagram from '../../images/Instagram.svg'
import Whatsapp from '../../images/Whatsapp.svg'
import LINKS from '../../utils/links'
import './styles.css'

export class Header extends Component {
  render() {
    return (
      <div className='header-container'>
        <div className='header-innercontainer'>
            <img src={orbLogo} className="App-logo" alt="logo" width='12%' />
              <div className='header-left-items'>
              <a href="#SOBRE"><p className='header-item'>SOBRE</p></a>
              <a href="#BENEFICIOS"><p className='header-item'>BENEFÍCIOS</p></a>
              <a href="#COMPRAR"><button className='button-buy'>COMPRAR</button> </a>
              <a target="_blank" href={LINKS.INSTAGRAM} rel="noreferrer"><img src={Instagram} alt="" style={{width: 20, marginRight: 20,marginLeft: 20}} /></a>
              <a target="_blank" href={LINKS.WHATSAPP} rel="noreferrer"><img src={Whatsapp} alt="" style={{width: 20, fill: 'black'}} /></a>
            </div>
        </div> 
      </div>
    )
  }
}


export default Header