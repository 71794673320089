import React from 'react'
import SiteMobile from './SiteMobile'
import SiteWeb from './SiteWeb'

export default function Home() {
  const isMobile = navigator.userAgent.includes('Android') || navigator.userAgent.includes('iPhone')

  return !isMobile ? (
    <SiteWeb />
  ) : (
    <SiteMobile />
  )
}
